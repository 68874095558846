<template>
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.375 33.75C9.375 31.7609 10.1652 29.8532 11.5717 28.4467C12.9782 27.0402 14.8859 26.25 16.875 26.25H43.125C45.1141 26.25 47.0218 27.0402 48.4283 28.4467C49.8348 29.8532 50.625 31.7609 50.625 33.75V52.5C50.625 54.4891 49.8348 56.3968 48.4283 57.8033C47.0218 59.2098 45.1141 60 43.125 60H16.875C14.8859 60 12.9782 59.2098 11.5717 57.8033C10.1652 56.3968 9.375 54.4891 9.375 52.5V33.75Z"
      fill="#F0F0F0" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.875 15C16.875 11.519 18.2578 8.18064 20.7192 5.71922C23.1806 3.25781 26.519 1.875 30 1.875C33.481 1.875 36.8194 3.25781 39.2808 5.71922C41.7422 8.18064 43.125 11.519 43.125 15V26.25H39.375V15C39.375 12.5136 38.3873 10.129 36.6291 8.37087C34.871 6.61272 32.4864 5.625 30 5.625C27.5136 5.625 25.129 6.61272 23.3709 8.37087C21.6127 10.129 20.625 12.5136 20.625 15V26.25H16.875V15Z"
      fill="#F0F0F0" />
  </svg>
</template>
