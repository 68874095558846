<script lang="ts" setup>
import { TransitionPresets } from '@vueuse/core'

import FriendsStats from '@/components/FriendsStats.vue'
import IconPerson from '@/components/icons/IconPerson.vue'
import InfoBtn from '@/components/InfoBtn.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { levelThresholds } from '@/const'
import data from '@/data'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'

const store = useAppStore()
const userStore = useUserStore()

const invites = computed(() => userStore.user.direct_invites + userStore.user.indirect_invites)

const nextLvlAmount = computed(
  () => levelThresholds[userStore.level - 1] ?? levelThresholds[levelThresholds.length - 1],
)

const progressStats = computed(() =>
  userStore.level > levelThresholds.length
    ? `${invites.value}`
    : `${invites.value}/${nextLvlAmount.value}`,
)

const progress = computed(() => {
  if (invites.value < 1) return 0
  if (invites.value > levelThresholds[levelThresholds.length - 1]) return 100
  return (100 * invites.value) / nextLvlAmount.value
})

const progressAnim = useTransition(progress, {
  duration: 400,
  transition: TransitionPresets.easeOutQuad,
})

function showModal() {
  store.openModal('level')
}
</script>

<template>
  <div class="grid w-full grid-cols-[1fr_75px_75px] gap-x-4">
    <div
      :class="`
        flex flex-col rounded-[20px] border border-solid border-brown bg-brown px-1.5 pt-1.5
        shadow-stats
      `">
      <p class="flex items-center">
        <v-icon :icon="IconPerson" />
        <span class="ml-1 text-[0.875rem]/[inherit] font-semibold">{{ progressStats }}</span>
        <InfoBtn class="ml-auto" @click="showModal" />
      </p>
      <ProgressBar :progress="progressAnim" root-class="h-4 bg-dark" bar-class="bg-primary" />
    </div>
    <FriendsStats :text="data.titleLvl1" :amount="userStore.user.direct_invites" />
    <FriendsStats :text="data.titleLvl2" :amount="userStore.user.indirect_invites" />
  </div>
</template>
