<script setup lang="ts">
import WebApp from '@twa-dev/sdk'
import { TransitionPresets } from '@vueuse/core'
import { lightTheme, Notification, Notivue, type NotivueTheme, push } from 'notivue'

import ModalBg from '@/assets/modalBg.webp'
import IconAlert from '@/components/icons/IconAlert.vue'
import IconCheck from '@/components/icons/IconCheck.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { useTouch } from '@/composables/useTouchFix'
import { useWS } from '@/composables/useWS'
import { actions, preloadImg, updateUrl, w8 } from '@/const'
import siteData from '@/data'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'

const theme: NotivueTheme = {
  ...lightTheme,
  '--nv-global-bg': 'var(--beige)',
  '--nv-global-border': 'var(--primary)',
  '--nv-global-fg': 'var(--dark)',
  '--nv-success-accent': 'var(--dark)',
  '--nv-radius': '8px',
  '--nv-icon-size': '1rem',
  '--nv-width': '320px',
  '--nv-min-width': '320px',
  '--nv-spacing': '0.625rem',
  '--nv-y-align': 'center',
  '--nv-border-width': '0',
  '--nv-shadow': '0 0.5rem 0.5rem rgba(0, 0, 0, 0.1)',
}
const icons = {
  success: markRaw(IconCheck),
}

const appStore = useAppStore()
const userStore = useUserStore()
const router = useRouter()

let progressDefDur = 1500
const progress = ref(0)
const progressDur = computed(() => (progress.value === 100 ? progressDefDur : 130))
const progressAnim = useTransition(progress, {
  duration: progressDur,
  transition: TransitionPresets.easeInOutQuad,
})

let interval: number | null = null

function stopProgress() {
  if (interval) {
    clearInterval(interval)
    interval = null
  }
}

function startProgress() {
  if (interval) return
  const treshholds = [20, 50, 70, 86, 95, 98.5, 99.99]
  const values = [4, 2, 1.1, 0.5, 0.15, 0.025, 0.006]
  interval = window.setInterval(() => {
    const ind = treshholds.findIndex((numb) => progress.value < numb)
    progress.value =
      ind >= 0 ? progress.value + values[ind] : progress.value < 100 ? progress.value : 100
    if (progress.value === 100) stopProgress()
  }, 80)
}

const dynamicStyles = computed(() => ({
  '--bg-blur': appStore.modalOpened ? '5px' : 'none',
}))

const { onDataCb, connect } = useWS()

watch(
  () => userStore.user.id,
  (id) => {
    if (!id) return

    connect()

    onDataCb.value = (data) => {
      if (data.action !== actions.invite) return
      const { firstName, username, depth } = data.payload as MessagePayload
      userStore.user[depth === 1 ? 'direct_invites' : 'indirect_invites'] += 1
      push.success(`Твой кент ${firstName} ${username || ''} ${depth} уровня залетел в хату`)
    }
  },
)

const appError = ref<null | string>(null)

const validate = async (): Promise<void> => {
  try {
    if (!WebApp) {
      throw new Error(siteData.errorTgWebApp)
    }
    const reqStart = performance.now()

    const response = await fetch(updateUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ initData: WebApp.initData }),
    })
    stopProgress()
    const reqDiff = performance.now() - reqStart
    progressDefDur = reqDiff > 50 && reqDiff < progressDefDur - 50 ? progressDefDur - reqDiff : 50
    progress.value = 100

    const result: ValidationResponse = await response.json()

    if (!result.valid) {
      throw new Error(result.error || 'Invalid data')
      // const user = WebApp?.initDataUnsafe.user || {
      //   id: 2232,
      //   username: 'test',
      //   first_name: 'xxdxd',
      // }
      // const { id, username, first_name } = user
      // userStore.setUser({
      //   id,
      //   first_name,
      //   username,
      //   direct_invites: 0,
      //   indirect_invites: 0,
      // })
      // return
    }

    const { tg_id, first_name, username, direct_invites, indirect_invites } = result.userData

    userStore.setUser({
      id: tg_id,
      first_name,
      username,
      direct_invites,
      indirect_invites,
    })
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === 'Data is outdated') {
        WebApp.close()
      }
      appError.value = err.message
    } else if (typeof err === 'string') {
      appError.value = err
    } else {
      appError.value = siteData.unexpectedErrorMsg
    }
  } finally {
    await w8(progressDefDur + 25)
    appStore.isLoading = false
    preloadImg(ModalBg)
  }
}

validate()

useTouch()

onMounted(() => {
  WebApp.setHeaderColor('#2A2A29')
  WebApp.setBackgroundColor('#FFFFFF')
  startProgress()
  appStore.isMounted = true
})

onUnmounted(() => {
  stopProgress()
})

onErrorCaptured((e: unknown) => {
  appError.value = e instanceof Error ? e.message : 'Произошла непредвиденная ошибка'
  return false
})
</script>

<template>
  <transition name="fade">
    <div
      v-if="appStore.isLoading || !appStore.isMounted || appError"
      key="wrap"
      class="fixed left-0 top-0 z-[9999] flex size-full items-center justify-center bg-dark">
      <div v-if="appStore.isLoading || !appStore.isMounted" class="size-full">
        <img
          height="100vh"
          aspect-ratio="2/3"
          class="size-full max-h-full lg:object-contain"
          src="/load.webp" />
        <div
          class="absolute bottom-[5%] left-0 flex w-full flex-col items-center justify-center gap-y-1">
          <span
            class="after:dots order-2 block text-2xl font-semibold text-[#ffb901] after:content-['.']">
            {{ siteData.loading }}
          </span>
          <ProgressBar
            :progress="progressAnim"
            root-class="my-auto h-6 w-72 overflow-hidden rounded-full bg-[#343A32] p-1"
            bar-class="brounded-full bg-[#ffb901] h-[calc(100%_-_0.5rem)" />
        </div>
      </div>
      <v-empty-state
        v-else-if="appError"
        :headline="siteData.errorTitle"
        :title="appError"
        class="m-auto -mt-3 h-screen"
        :icon="IconAlert">
        <template #actions>
          <v-spacer />
          <v-btn
            color="green-darken-2"
            class="rounded-xl text-lg normal-case"
            text="Перезагрузить"
            @click="router.go(0)" />
        </template>
      </v-empty-state>
    </div>
  </transition>
  <v-app class="h-full overflow-y-auto">
    <v-main
      v-if="!appError"
      :class="`
        relative z-10 flex flex-col items-center justify-center gap-5 overflow-hidden
        bg-[url('@/assets/bgPrison.webp')] bg-cover bg-center bg-no-repeat !px-[18px] !pb-4 !pt-[25px]
        blur-[var(--bg-blur)] transition-none before:absolute before:left-0 before:top-0 before:-z-one
        before:size-full before:content-empty
      `"
      :style="dynamicStyles">
      <RouterView />
    </v-main>
    <ModalDialog v-model="appStore.modalOpened" />
    <Notivue v-slot="item">
      <Notification :item :theme :icons class="poppins text-base" />
    </Notivue>
  </v-app>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.23s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translate3d(0, 0, -60px) scale(0.96);
}
</style>
