<script lang="ts" setup>
const props = defineProps<{
  main?: boolean
  shadow?: boolean
}>()

const bg = props.main ? ` bg-primary ` : ` bg-dark `
</script>

<template>
  <v-btn
    v-ripple="{ class: 'text-orange-lighten-4' }"
    :style="{
      textTransform: 'none',
      borderRadius: '12px',
      boxShadow: shadow ? '0px 1px 15px 0px #E3AD1C80' : '0px 3px 4px 0px #775805',
    }"
    :class="`
      text-white${bg}font-semibold tracking-wider
    `"
    variant="flat"
    max-width="360"
    width="80%"
    height="40">
    <slot />
  </v-btn>
</template>
