<script lang="ts" setup>
import WebApp from '@twa-dev/sdk'
import { push } from 'notivue'

import FooterBtn from '@/components/FooterBtn.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import IconWeb from '@/components/icons/IconWeb.vue'
import data from '@/data'
import { useUserStore } from '@/stores/userStore'

const userStore = useUserStore()
const botName = import.meta.env.VITE_BOT_NAME
const botAppName = import.meta.env.VITE_BOT_APP_NAME

const source = computed(() => {
  const param = userStore.user.username ?? userStore.user.id
  const link = `https://t.me/${botName}/${botAppName}?startapp=${param}`
  return link
})

const { copy } = useClipboard({ source, legacy: true })

// const referralLink = `https://t.me/${botName}?start=${userStore.user.id}`;
// WebApp.switchInlineQuery(referralLink, ['users', 'groups', 'channels']);
// tg://resolve?domain=${botName}&appname=${r}&startapp=${i}
// `tg://msg_url?url=${url}&text=${data.inviteText}`
function onInviteClick() {
  const url = encodeURIComponent(source.value)
  const shareUrl = `https://t.me/share/url?url=${url}&text=${encodeURIComponent(data.inviteText)}`
  WebApp.openTelegramLink(shareUrl) // window.open(shareUrl, '_blank')  // closes mini app on desktop
}

function onCopyClick() {
  copy(source.value)
  push.success(data.copyTitle)
}

function onJoinClick() {
  WebApp.openTelegramLink(data.joinGroup)
}
</script>

<template>
  <footer class="relative flex w-full flex-col gap-y-3 rounded-2xl p-4">
    <div class="flex justify-between">
      <FooterBtn main @click="onInviteClick">{{ data.inviteBtn }}</FooterBtn>
      <v-btn
        color="primaryLight"
        variant="tonal"
        class="relative !rounded-xl bg-beige !shadow-copy"
        size="40"
        @click="onCopyClick">
        <IconCopy class="relative z-10" />
      </v-btn>
    </div>
    <FooterBtn shadow class="!border-b border-solid !border-b-primary" @click="onJoinClick">{{
      data.joinBtn
    }}</FooterBtn>
    <IconWeb class="absolute bottom-0 right-0" />
  </footer>
</template>

<style lang="postcss" scoped>
footer {
  background: linear-gradient(180deg, var(--brown) 0%, var(--dark) 100%);
}
</style>
