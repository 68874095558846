<script lang="ts" setup>
import InfoBtn from '@/components/InfoBtn.vue'
import data from '@/data'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'

const appStore = useAppStore()
const userStore = useUserStore()

function showModal() {
  appStore.openModal('person')
}

let firstVisit = true

watch(
  () => appStore.isLoading,
  async () => {
    await nextTick()
    firstVisit = false
  },
  { once: true, flush: 'post' },
)
</script>

<template>
  <div class="flex max-h-[26rem] w-full flex-1 flex-col items-center">
    <transition mode="out-in" :name="firstVisit ? 'none' : 'v'">
      <v-img
        :key="userStore.level"
        class="pointer-events-none"
        width="auto"
        :height="180"
        :draggable="false"
        :transition="false"
        :src="data.items[userStore.level - 1].i"
        :alt="data.userImgAltText" />
    </transition>
    <div
      :class="`
        relative z-10 -mt-0.5 flex h-[67px] w-[282px] flex-col items-center
        bg-[url(@/assets/Menu.svg)] pt-2 before:absolute before:left-[calc(50%-207px/2)]
        before:top-[calc(50%-51px/2)] before:-z-one before:h-[51px] before:w-[207px] before:bg-orange
        before:blur-sm before:content-empty
      `">
      <div class="flex w-1/2 content-center items-center justify-center gap-x-4">
        <h2 class="text-sm font-semibold text-dark">{{ data.items[userStore.level - 1].l }}</h2>
        <InfoBtn @click="showModal" />
      </div>
      <h1 class="text-center text-lg font-semibold">
        {{ data.items[userStore.level - 1].n }}
      </h1>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.v-enter-active {
  transition: 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.v-leave-active,
.none-enter-active {
  transition: none;
}
.v-enter-active {
  opacity: 1;
}

.v-enter-from {
  opacity: 0;
}
</style>
