import WebApp from '@twa-dev/sdk'

export async function useTouch() {
  let shouldExpand = false

  function handleViewportChange({ isStateStable }: { isStateStable: boolean }) {
    setRealVH()

    if (isStateStable) {
      if (WebApp.isExpanded && WebApp.isVerticalSwipesEnabled) {
        WebApp.disableVerticalSwipes()
      }

      if (!WebApp.isExpanded || shouldExpand) {
        WebApp.expand()
        shouldExpand = false
      }
      return
    }

    if (
      !WebApp.isVerticalSwipesEnabled &&
      WebApp.isExpanded &&
      Math.abs(window.innerHeight - WebApp.viewportHeight) > 2
    ) {
      shouldExpand = true
    }
  }

  function findScrollableElement(el: HTMLElement): HTMLElement | null {
    let currentElement: HTMLElement | null = el
    while (currentElement) {
      const overflowY = window.getComputedStyle(currentElement).overflowY
      const isScrollable = overflowY === 'auto' || overflowY === 'scroll'
      if (isScrollable && currentElement.scrollHeight > currentElement.clientHeight) {
        return currentElement
      }
      currentElement = currentElement.parentElement
    }
    return null
  }

  function handleTouchStart(e: TouchEvent) {
    const target = findScrollableElement(e.target as HTMLElement)
    if (!target) return
    target.dataset.touchStartY = String(e.touches[0].clientY)
  }

  function preventTouchMove(e: TouchEvent) {
    if (!e.cancelable) return
    const target = findScrollableElement(e.target as HTMLElement)
    if (!target) {
      e.preventDefault()
      return
    }
    const startY = parseFloat(target.dataset.touchStartY || '0')
    const currentY = e.touches[0].clientY
    const scrollTop = target.scrollTop
    const scrollHeight = target.scrollHeight
    const clientHeight = target.clientHeight
    const atTop = scrollTop === 0
    const atBottom = scrollTop + clientHeight >= scrollHeight
    if ((atTop && currentY > startY) || (atBottom && currentY < startY)) {
      e.preventDefault()
    }
  }

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  let lastTouchEnd = 0

  function handleDoubleTapIOS(e: TouchEvent) {
    const now = new Date().getTime()
    const timeSinceLastTouch = now - lastTouchEnd
    if (timeSinceLastTouch <= 300 && timeSinceLastTouch > 0) {
      e.preventDefault()
    }
    lastTouchEnd = now
  }

  function handleAppMaximize() {
    if (shouldExpand) {
      WebApp.expand()
      shouldExpand = false
    }
  }

  function setRealVH() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  onMounted(() => {
    window.addEventListener('resize', setRealVH)
    setRealVH()
    WebApp.onEvent('viewportChanged', handleViewportChange)
    useEventListener(document.querySelector('.v-application'), 'touchend', handleAppMaximize, {
      passive: false,
    })
    if (isIOS || isSafari) {
      const html = document.documentElement
      useEventListener(html, 'touchstart', handleTouchStart, {
        passive: false,
      })
      useEventListener(html, 'touchmove', preventTouchMove, {
        passive: false,
      })
      useEventListener(html, 'touchend', handleDoubleTapIOS, {
        passive: false,
      })
    }
  })

  onUnmounted(() => {
    WebApp.offEvent('viewportChanged', handleViewportChange)
  })
}
