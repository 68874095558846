<script setup lang="ts">
import FooterBtn from '@/components/FooterBtn.vue'
import IconLock from '@/components/icons/IconLock.vue'
import PaginBtn from '@/components/PaginBtn.vue'
import { preloadImg } from '@/const'
import data from '@/data'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'

const { items, modalInfo, modalLevels, modalAcceptBtn, modalBackBtn, modalForwardBtn } = data

const store = useAppStore()
const userStore = useUserStore()

const currInd = ref(userStore.level - 1)
const prevInd = ref(userStore.level - 2)

watch(
  () => userStore.level,
  (val) => {
    currInd.value = val - 1
    prevInd.value = val - 2
  },
)

const model = defineModel<boolean>()

const isOpen = ref(model.value)

watch(
  () => model.value,
  (newVal) => {
    isOpen.value = newVal
  },
)

watchEffect(
  () => {
    if (store.modalType !== 'person') return
    const index = (currInd.value + 1) % items.length
    if (index >= 0 && index < items.length) {
      if ('requestIdleCallback' in window) {
        requestIdleCallback(() => {
          preloadImg(items[index].i)
        })
      } else {
        preloadImg(items[index].i)
      }
    }
  },
  { flush: 'post' },
)

const isAnimating = ref(false)

const sheetClasses = computed(() => {
  const overflowY = isAnimating.value ? ` !overflow-y-hidden ` : ` !overflow-y-auto `
  let classes = `
    relative !overflow-x-hidden overscroll-none !rounded-xl !bg-[url(@/assets/modalBg.webp)] bg-cover
    bg-no-repeat bg-blend-overlay transition-colors duration-300
  `
  classes += ` ${overflowY}`
  if (store.modalType === 'person') {
    classes += ' -mt-[10vh]'
  }
  return classes
})

const close = () => {
  model.value = false
}

function handleEscKey(e: KeyboardEvent) {
  if (!isOpen.value) return
  switch (e.key) {
    case 'Escape':
      close()
      break
    case 'ArrowLeft':
      handleBack()
      break
    case 'ArrowRight':
      handleForward()
      break
  }
}

function handleLeave() {
  store.modalType = ''
  currInd.value = userStore.level - 1
  prevInd.value = userStore.level - 2
  isAnimating.value = false
}

onMounted(() => {
  window.addEventListener('keydown', handleEscKey)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscKey)
})

function handleForward() {
  if (!isAnimating.value && currInd.value < items.length - 1) {
    prevInd.value = currInd.value
    currInd.value += 1
  }
}

function handleBack() {
  if (!isAnimating.value && currInd.value > 0) {
    prevInd.value = currInd.value
    currInd.value -= 1
  }
}

function onBeforeEnter() {
  isAnimating.value = true
}

function onAfterEnter() {
  isAnimating.value = false
}
</script>

<template>
  <v-dialog
    v-model="isOpen"
    eager
    max-width="360"
    scrim="black"
    @click:outside="close"
    @after-leave="handleLeave">
    <v-sheet
      :style="{
        backgroundColor:
          store.modalType === 'person' && currInd + 1 > userStore.level
            ? '#2a2a2975'
            : 'transparent',
      }"
      :class="sheetClasses">
      <div class="relative flex flex-col gap-y-8 overflow-hidden px-3 py-4">
        <template v-if="store.modalType === 'info' || store.modalType === 'level'">
          <p
            class="whitespace-pre-line px-3 pt-4 text-base tracking-wide text-black"
            v-html="store.modalType === 'info' ? modalInfo : modalLevels" />
          <FooterBtn class="mb-4 self-center text-base" @click="close">
            {{ modalAcceptBtn }}
          </FooterBtn>
        </template>
        <template v-else-if="store.modalType === 'person'">
          <Transition
            :name="prevInd < currInd ? 'slideIn' : 'slideOut'"
            @after-enter="onAfterEnter"
            @before-enter="onBeforeEnter">
            <div
              :key="currInd"
              :class="`relative flex h-[350px] items-center gap-x-2 will-change-transform`">
              <img
                width="100%"
                :style="{ filter: currInd + 1 > userStore.level ? 'brightness(0.7)' : 'none' }"
                class="h-[280px] min-w-0 shrink-0 basis-[calc(50%_-_1rem)] object-contain"
                :src="items[currInd].i"
                :draggable="false"
                :alt="`Персонаж ${currInd + 1}го Уровня`" />
              <div :class="`flex flex-1 basis-[calc(50%_-_1rem)] flex-col gap-y-1 text-black`">
                <h3 class="text-base/5 font-semibold">{{ items[currInd].l }}</h3>
                <h2 class="mb-0.5 text-lg/6 font-semibold">{{ items[currInd].n }}</h2>
                <p class="roboto text-[13px]/[15px]">{{ items[currInd].d }}</p>
              </div>
              <IconLock
                v-if="currInd + 1 > userStore.level"
                class="absolute left-[calc(25%_-_30px_-_0.5rem)] top-[calc(50%_-_30px)] z-[5555]" />
            </div>
          </Transition>
        </template>
      </div>
    </v-sheet>
    <Teleport to="body">
      <v-dialog-transition>
        <v-btn
          v-if="store.modalType === 'person' && store.modalOpened"
          color="primaryLight"
          variant="tonal"
          :class="`
            !fixed right-6 top-[calc(((100vh_-_10vh_-_382px)_/_4)_-_20px)] z-[5555] !rounded-xl
            bg-beige !shadow-copy
          `"
          size="40"
          @click="close">
          <span
            :class="`
              relative z-10 inline-block w-3 after:absolute after:left-[5px] after:top-[-9px]
              after:h-[18px] after:w-[2px] after:-rotate-45 after:rounded-sm after:bg-dark
              after:content-empty before:absolute before:left-[5px] before:top-[-9px] before:h-[18px]
              before:w-[2px] before:rotate-45 before:rounded-sm before:bg-dark before:content-empty
            `" />
        </v-btn>
      </v-dialog-transition>
      <v-dialog-transition>
        <div
          v-if="store.modalType === 'person' && store.modalOpened"
          :class="`
            fixed bottom-[calc(((100vh_-_10vh_-_191px)_/_4)_-_20px)] z-[5555] flex w-full
            justify-center gap-x-10 pointer-events-none
          `">
          <PaginBtn :is-active="currInd > 0" @click="handleBack">{{ modalBackBtn }}</PaginBtn>
          <PaginBtn
            :is-forward="true"
            :is-active="currInd < items.length - 1"
            @click="handleForward">
            {{ modalForwardBtn }}
          </PaginBtn>
        </div>
      </v-dialog-transition>
    </Teleport>
  </v-dialog>
</template>

<style lang="postcss" scoped>
.slideIn-enter-active,
.slideIn-leave-active,
.slideOut-enter-active,
.slideOut-leave-active {
  transition: all 0.21s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.slideIn-enter-from {
  opacity: 0;
  transform: translateX(calc(100% + 1.5rem));
}

.slideOut-enter-from {
  opacity: 0;
  transform: translateX(calc(-100% - 1.5rem));
}

.slideIn-leave-to {
  opacity: 0;
  transform: translateX(calc(-100% - 1.5rem));
}

.slideOut-leave-to {
  opacity: 0;
  transform: translateX(calc(100% + 1.5rem));
}

.slideIn-leave-active,
.slideOut-leave-active {
  position: absolute;
  /* top: 2.5rem;
  left: 1.5rem; */
  width: calc(100% - 1.5rem);
}
</style>
