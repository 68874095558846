<script lang="ts" setup>
import IconArrow from '@/components/icons/IconArrow.vue'

const props = defineProps<{
  isForward?: boolean
  isActive?: boolean
}>()

const color = computed(() => (props.isActive ? 'beige' : 'light'))
const icon = computed(() =>
  props.isForward
    ? { 'append-icon': h(IconArrow, { isActive: props.isActive }) }
    : {
        'prepend-icon': h(IconArrow, { isActive: props.isActive, class: 'rotate-180' }),
      },
)
</script>

<template>
  <v-btn
    v-ripple="{ class: isActive ? 'text-amber-lighten-3' : 'text-grey-lighten-1' }"
    :color
    :class="['flex', 'pointer-events-auto', isActive ? 'text-black' : 'text-grey']"
    v-bind="icon"
    :style="{
      fontSize: '14px',
      letterSpacing: '0.5px',
      textTransform: 'none',
      padding: '0.5rem 0.25rem',
      borderRadius: '12px',
    }"
    variant="flat"
    width="86"
    height="40">
    <slot />
  </v-btn>
</template>
