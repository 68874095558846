import { actions, isProd } from '@/const'
import { useUserStore } from '@/stores/userStore'

const wsUrl = isProd
  ? import.meta.env.VITE_WS_URL
  : `ws://localhost:${import.meta.env.VITE_SITE_PORT || 8888}`
const maxReconnectAttempts = 5
const initialReconnectDelay = 3000

export function useWS() {
  const socket = ref<WebSocket | null>(null)
  const onDataCb = ref<(data: WebSocketMessage) => void>(() => {})
  const userStore = useUserStore()
  let reconnectAttempts = 0

  function handleReconnect(event?: CloseEvent): void {
    if (!event?.wasClean && reconnectAttempts < maxReconnectAttempts) {
      const reconnectDelay = Math.min(initialReconnectDelay * Math.pow(2, reconnectAttempts), 60000)
      setTimeout(() => {
        reconnectAttempts++
        connect()
      }, reconnectDelay)
    } else if (reconnectAttempts >= maxReconnectAttempts) {
      console.log('Max reconnect attempts reached')
    }
  }

  function sendMessage(message: WebSocketMessage): void {
    if (socket.value && socket.value.readyState === WebSocket.OPEN) {
      socket.value.send(JSON.stringify(message))
    } else {
      console.log('WebSocket is not open. Message not sent.')
    }
  }

  function connect(): void {
    if (socket.value) {
      socket.value.close()
      socket.value = null
    }

    socket.value = new WebSocket(wsUrl)

    socket.value.onopen = () => {
      console.log('Connected to server')
      reconnectAttempts = 0
      if (userStore.user.id) {
        sendMessage({ action: actions.identify, payload: { id: userStore.user.id } })
      }
    }

    socket.value.onmessage = (event: MessageEvent) => {
      try {
        const data: WebSocketMessage = JSON.parse(event.data)
        onDataCb.value(data)
      } catch (err) {
        console.error('Error parsing message:', err)
      }
    }

    socket.value.onclose = (event: CloseEvent) => {
      console.log('Disconnected from server', event.reason)
      handleReconnect(event)
    }

    socket.value.onerror = (error: Event) => {
      console.error('WebSocket error:', error)
      handleReconnect()
    }
  }

  function handleVisibilityChange() {
    if (document.hidden) return
    if (!socket.value || socket.value.readyState === WebSocket.CLOSED) {
      connect()
    }
  }

  onMounted(() => {
    useEventListener(document, 'visibilitychange', handleVisibilityChange)
  })

  onUnmounted(() => {
    if (socket.value) {
      socket.value.close()
    }
  })

  return {
    socket,
    onDataCb,
    connect,
  }
}
