<script setup lang="ts">
import BorderImg from '@/components/icons/BorderImg.vue'
import InfoBtn from '@/components/InfoBtn.vue'
import data from '@/data'
import { useAppStore } from '@/stores/appStore'

const store = useAppStore()

function showModal() {
  store.openModal('info')
}
</script>

<template>
  <header
    :class="`
      relative z-0 max-w-80 rounded-2xl px-7 pb-6 pt-3 before:absolute before:left-0 before:top-0
      before:-z-one before:h-full before:w-full before:rounded-2xl before:bg-brown before:blur-[3px]
      before:content-empty
    `">
    <BorderImg class="pointer-events-none absolute left-[-10%] top-[-20%] h-[140%] w-[118%]" />
    <div class="mb-1.5 flex items-center justify-center gap-x-4">
      <h3 class="text-xl font-semibold text-black">{{ data.headerTitle }}</h3>
      <InfoBtn @click="showModal" />
    </div>
    <p class="poppins text-center text-sm leading-4">{{ data.headerDesc }}</p>
  </header>
</template>
