import { defineStore } from 'pinia'
import { reactive } from 'vue'

import { calculateUserLevel } from '@/const'

export const useUserStore = defineStore('user', () => {
  const user = reactive<User>({
    id: null,
    first_name: '',
    direct_invites: 0,
    indirect_invites: 0,
    username: null,
  })

  const level = computed(() => calculateUserLevel(user.direct_invites + user.indirect_invites))

  const setUser = (userInfo: User) => {
    Object.assign(user, userInfo)
  }

  // persist: {
  //   enabled: true,
  //   strategies: [{ storage: localStorage, paths: ["theme"], }],
  // },

  return { user, setUser, level }
})
