import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppStore = defineStore('app', () => {
  const modalOpened = ref(false)
  const modalType = ref<ModalTypes>('')
  const isLoading = ref(true)
  const isMounted = ref(false)

  const openModal = (type: ModalTypes) => {
    modalOpened.value = true
    modalType.value = type
  }

  const closeModal = () => {
    modalOpened.value = false
    modalType.value = ''
  }

  return { modalOpened, openModal, closeModal, modalType, isLoading, isMounted }
})
