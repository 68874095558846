if (!Array.prototype.at || !String.prototype.at) {
  function polyfillAt(index) {
    const length = this.length
    const relativeIndex = index < 0 ? Math.max(length + index, 0) : Math.min(index, length - 1)
    return this instanceof Array ? this[relativeIndex] : this.charAt(relativeIndex)
  }

  Array.prototype.at = polyfillAt
  String.prototype.at = polyfillAt
}
