export const updateUrl = '/api/client'

export const levelThresholds = [3, 10, 25, 50, 75, 150, 300, 500, 1000]

export function calculateUserLevel(invites: number): number {
  for (let i = 0; i < levelThresholds.length; i++) {
    if (invites < levelThresholds[i]) {
      return i + 1
    }
  }
  return levelThresholds.length + 1
}

export function preloadImg(imgUrl: string) {
  const img = new Image()
  img.src = imgUrl
}

export async function w8(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms, true))
}

export const isProd = process.env.NODE_ENV === 'production'

export const actions = {
  invite: 'Invite',
  identify: 'identify',
} as const
