<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.17755 2.1437C8.43959 1.85856 9.76 1.98901 10.9418 2.51561C11.2782 2.66546 11.6723 2.51431 11.8221 2.17799C11.972 1.84168 11.8208 1.44756 11.4845 1.29771C10.04 0.654086 8.42621 0.49464 6.88371 0.843146C5.34121 1.19165 3.9527 2.02944 2.92524 3.23156C1.89779 4.43368 1.28645 5.93572 1.1824 7.51367C1.07835 9.09162 1.48717 10.6609 2.34787 11.9876C3.20858 13.3142 4.47507 14.327 5.95845 14.8751C7.44183 15.4231 9.06262 15.4769 10.5791 15.0286C12.0956 14.5802 13.4265 13.6536 14.3733 12.387C15.3202 11.1205 15.8323 9.58175 15.8332 8.00037V7.38666C15.8332 7.01847 15.5347 6.71999 15.1665 6.71999C14.7983 6.71999 14.4998 7.01847 14.4998 7.38666V7.99961C14.4991 9.29346 14.0801 10.5524 13.3054 11.5887C12.5307 12.625 11.4418 13.3831 10.2011 13.75C8.9603 14.1168 7.63419 14.0728 6.42052 13.6244C5.20685 13.176 4.17063 12.3473 3.46641 11.2619C2.7622 10.1764 2.42771 8.89245 2.51284 7.6014C2.59798 6.31035 3.09816 5.08141 3.93881 4.09785C4.77945 3.1143 5.91551 2.42884 7.17755 2.1437Z"
      fill="#30E02D" />
    <path
      d="M15.6381 3.13784C15.8984 2.87736 15.8982 2.45525 15.6377 2.19503C15.3772 1.93481 14.9551 1.93502 14.6949 2.1955L8.4996 8.39696L6.97124 6.8686C6.71089 6.60825 6.28878 6.60825 6.02843 6.8686C5.76808 7.12895 5.76808 7.55106 6.02843 7.81141L8.02843 9.81141C8.1535 9.93647 8.32314 10.0067 8.5 10.0067C8.67687 10.0066 8.84648 9.9363 8.97148 9.81117L15.6381 3.13784Z"
      fill="#30E02D" />
  </svg>
</template>
