const data = {
  siteTitle: 'Тюряга',
  inviteBtn: 'Пригласи кента',
  joinBtn: 'Залетай в хату',
  joinGroup: 'https://t.me/PrisonTONx',
  copyTitle: 'Ссылка скопирована!',
  statsFriends: 'Кенты',
  titleLvl1: 'Кенты\n1 уровня',
  titleLvl2: 'Кенты\n2-3 уровней',
  items: [
    {
      l: 'Уровень 1',
      n: 'Паша "Круассан"',
      i: 'lvl1.webp',
      d: 'С детства носил смартфон, а не рогатку. Мозг быстрее пальцев шевелился. Создал приложение, затянувшее всех: от школьников до бизнесменов. Конкурентов обгонял, но свободы хотел больше, чем денег. Поймали на подлёте - залетел за решётку. На зоне свои порядки: цифры и коды. За ним идут и арестанты, и охрана. Вся жизнь как взлом - один неверный шаг, и ты в системе. Не будь как Паша, будь проще, жми на паузу.',
    },
    {
      l: 'Уровень 2',
      n: 'Майор "Петушенко"',
      i: 'lvl2.webp',
      d: 'Бывший вертухай, беспределил по полной, не жалел ни одного зэка.\nБабки, контрабас, кумовские мутки - всё это про майора. Под конец карьеры козырные взяли его за жабры, потому что не поделился кушем и загремел в тюрягу.\nТеперь его жизнь - полный хаос. Не будь как Петушенко, делись с братвой, хотя бы реферальной малявой.',
    },
    {
      l: 'Уровень 3',
      n: 'Игорёк "Чертополох"',
      i: 'lvl3.webp',
      d: 'Все, кто помнят его на районе, говорят, что он был беспонтовый. На свободе был неряшливым, несерьезным, подставлял своих кентов ради кормушки и подлизывал хозяину. На зоне выполняет грязную работу, не спорит и не отсвечивает, проводя время у умывальника.',
    },
    {
      l: 'Уровень 4',
      n: 'Толик "3 корочки"',
      i: 'lvl4.webp',
      d: 'Смышленый парень, с детства качал мозги, был со всеми в ладах, помогал старшим. Будучи школяром попался на подделке документов - приняли с тремя липовыми ксивами ГИБДД. После малолетки продолжил мутить с паспортами и корочками. Помогал авторитетам оттяпать лакомые куски гос. собственности. Загремел по подставе бывшего кореша.',
    },
    {
      l: 'Уровень 5',
      n: 'Артур "Паровоз"',
      i: 'lvl5.webp',
      d: 'Родился дохляком, до пятого класса был мелким и щуплым. В 14 лет получил капитальных люлей и начал качаться. Сбивал бабки с барыг, ломал кабины тем, кто не хотел с ним работать. На районе был в авторитете. До 18 всё сходило с рук, пока не отправил в больничку сына прокурора Бубу.',
    },
    {
      l: 'Уровень 6',
      n: 'Паша "Пресс"',
      i: 'lvl6.webp',
      d: 'Жёсткий пацан, с Прессом нашли общий язык единицы. На воле все вопросы решал без базара. В лучшем случае использовал кулаки, в худшем - всё, что попадалось под руку. Любит холодное оружие и крепкий алкоголь. Держится с блатными, гнёт свою линию и с администрацией не сотрудничает.',
    },
    {
      l: 'Уровень 7',
      n: 'Геша "Фокус"',
      i: 'lvl7.webp',
      d: 'Творческий парень: в театре мутил, шансон пел, но тяжёлая жизнь подтолкнула к карточным разводам. Изучил сотни "фокусов", обул кучу лохов. Сильно боялся спалиться, даже к пластическому хирургу за сменой внешности обратился. Вёл синдикат мошенников. Попался, когда разводил пассажира, который оказался копом под прикрытием. На любой зоне - уважаемый арестант.',
    },
    {
      l: 'Уровень 8',
      n: 'Сёма "Робин Гуд"',
      i: 'lvl8.webp',
      d: 'По молодухе был обычным пацаном, но умел постоять за себя при наезде. В армии что-то щелкнуло, и после службы Сёма собрал банду налетчиков, державших в страхе район, а потом и город. Грабил зажравшихся барыг и фарцовщиков, за что его поддерживали низы. Грел зоны и помогал нуждающимся. Зеки его уважают, а некоторые даже побаиваются...',
    },
    {
      l: 'Уровень 9',
      n: 'Роза "Колерованная"',
      i: 'lvl9.webp',
      d: 'Антиквариат её слабость. Семья Розы барыжила краденым и гнала контрабас. Воровка и мошенница с редким криминальным талантом. Дар мимикрии и артистизм позволяли ей развести любого фраера. Хитрость и беспредельность сделали её настоящей королевой криминального мира, гением аферы.',
    },
    {
      l: 'Уровень 10',
      n: 'Лёха "Ферзь"',
      i: 'lvl10.webp',
      d: 'Родился в захолустье, с детства понял, что выживает сильнейший, и сколотил банду голодной до наживы шпаны. В 15 лет приехал в столицу и начал воровать. Попал в тюрьму, где втерся к авторитетам и стал "козырным фраером". После первой ходки снова собрал банду, занимавшуюся грабежами и рэкетом. Вся банда была арестована, Ферзь получил 15 лет. В тюрьме его короновали.',
    },
  ],
  modalInfo: `Все твои кореша останутся с тобой навсегда и будут греть тебя всю дорогу. Живи по чести, не предавай своих, и всё будет ровно.\n\nПерсонаж сможет стать <strong>NFT</strong>, конечно если у тебя будет такой интерес`,
  modalLevels: `Уровень персонажа растет в зависимости от количества корешей, которых ты и твои пацаны затащили в игру.\n\nЧем выше уровень персонажа, тем больше плюшек он дает. Учитывается общее количество приглашенных корешей 1-го, 2-го и 3-го уровней\n\n1. Уровень - 1 кент\n2. Уровень - 3 кента\n3. Уровень - 10 кентов\n4. Уровень - 25 кентов\n5. Уровень - 50 кентов \n6. Уровень - 75 кентов \n7. Уровень - 150 кентов \n8. Уровень - 300 кентов \n9. Уровень - 500 кентов \n10. Уровень - 1000+ кентов`,
  modalAcceptBtn: 'Врубился',
  modalBackBtn: 'Назад',
  modalForwardBtn: 'Вперед',
  userImgAltText: 'Профиль персонажа',
  headerTitle: 'Зови корешей',
  headerDesc:
    'Чем больше корешей ты и твои братки затащите в игру, тем выше поднимется твой персонаж.',
  errorTitle: 'Ошибка при загрузки сайта',
  errorTgWebApp: 'Ошибка загрузки Telegram WebApp',
  inviteText: 'Присоединяйся ко мне в Тюрягу',
  unexpectedErrorMsg: 'Случилась неожиданная ошибка',
  loading: 'ЗАГРУЗКА',
}

export default data
