import 'notivue/notification.css'
import 'notivue/animations.css'
import '@fontsource/roboto-flex/cyrillic-400.css'
import './assets/main.css'
import './polyfill'

import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createGtm } from '@gtm-support/vue-gtm'
import WebApp from '@twa-dev/sdk'
import { createNotivue } from 'notivue'
import { createPinia } from 'pinia'
import { type ComponentPublicInstance, createApp } from 'vue'

import { isProd } from '@/const'
import vuetify from '@/plugins/vuetify'

import App from './App.vue'
import router from './router'

WebApp.ready()
WebApp.expand()

const notivue = createNotivue({
  notifications: {
    global: {
      duration: 3000,
    },
  },
  avoidDuplicates: true,
})

const gtm = createGtm({
  id: import.meta.env.VITE_GTM_ID,
  enabled: isProd,
  vueRouter: router,
})

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginVue()],
})
BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_KEY })
const bugsnagVue = Bugsnag.getPlugin('vue')

const app = createApp(App)
if (bugsnagVue) {
  app.use(bugsnagVue)
}
app.use(createPinia())
app.use(router)
app.use(gtm)
app.use(vuetify)
app.use(notivue)

if (!isProd) {
  app.config.errorHandler = async (
    error: unknown,
    vm: ComponentPublicInstance | null,
    info: string,
  ) => {
    const typedError = error as Error

    const errorDetails = {
      message: typedError.message,
      stack: typedError.stack,
      component: vm ? vm.$options.name || vm.$options._componentTag : 'N/A',
      info: info,
      userAgent: navigator.userAgent,
    }

    console.log('main57', errorDetails)

    // await fetch(updateUrl, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ errorLog: errorDetails }),
    // })

    // const gtag = useGtm()
    // gtag?.push({
    //   event: 'vueError',
    //   errorMessage: errorDetails.message,
    //   errorStack: errorDetails.stack,
    //   errorComponent: errorDetails.component,
    //   errorInfo: errorDetails.info,
    //   errorUserAgent: errorDetails.userAgent,
    // });
  }
}

app.mount('#app')
