<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8153 5.875H7.51599C6.50817 5.875 5.69116 6.71447 5.69116 7.75V15.25C5.69116 16.2855 6.50817 17.125 7.51599 17.125H14.8153C15.8232 17.125 16.6402 16.2855 16.6402 15.25V7.75C16.6402 6.71447 15.8232 5.875 14.8153 5.875ZM7.51599 7.125H14.8153C15.1513 7.125 15.4236 7.40482 15.4236 7.75V15.25C15.4236 15.5952 15.1513 15.875 14.8153 15.875H7.51599C7.18005 15.875 6.90772 15.5952 6.90772 15.25V7.75C6.90772 7.40482 7.18005 7.125 7.51599 7.125Z"
      fill="#E3AD1C" />
    <path
      d="M9.94911 0.875C10.9209 0.875 11.7153 1.65557 11.7708 2.63983L11.7739 2.75V4.625H10.5574V2.75C10.5574 2.42948 10.3226 2.16531 10.0201 2.1292L9.94911 2.125H2.64978C2.33784 2.125 2.08074 2.36628 2.0456 2.67711L2.04151 2.75V10.25C2.04151 10.5705 2.27633 10.8347 2.57885 10.8708L2.64978 10.875H4.47462V12.125H2.64978C1.67795 12.125 0.883552 11.3444 0.828049 10.3602L0.824951 10.25V2.75C0.824951 1.75145 1.58464 0.935212 2.54256 0.878183L2.64978 0.875H9.94911Z"
      fill="#E3AD1C" />
  </svg>
</template>
