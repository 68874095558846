<script lang="ts" setup>
const props = defineProps<{
  isActive?: boolean
}>()

const color = computed(() =>
  props.isActive ? ` before:bg-primary after:bg-primary ` : ` before:bg-grey after:bg-grey `,
)
</script>

<!-- <template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    class="z-10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.92049 0.334735C1.48116 -0.111578 0.768844 -0.111578 0.329505 0.334735C-0.109835 0.781048 -0.109835 1.50467 0.329505 1.95098L6.28401 8L0.329505 14.049C-0.109835 14.4953 -0.109835 15.219 0.329505 15.6653C0.768844 16.1116 1.48116 16.1116 1.92049 15.6653L8.6705 8.80812C9.10984 8.36181 9.10984 7.63819 8.6705 7.19188L1.92049 0.334735Z"
      :fill="color" />
  </svg>
</template> -->

<template>
  <span
    :class="`
      relative${color}inline-block z-10 w-3 after:absolute after:bottom-[-3px] after:h-[14px] after:w-[3px]
      after:-rotate-45 after:rounded-sm after:content-empty before:absolute before:top-[-3px]
      before:h-[14px] before:w-[3px] before:rotate-45 before:rounded-sm before:content-empty
    `" />
</template>
