import 'vuetify/styles'
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        colors: {
          primary: '#E3AD1C',
          primaryLight: '#eac96f',
          brown: '#9D8545',
          white: '#ffffff',
          dark: '#2A2A29',
          grey: '#70706A',
          beige: '#F9E8C9',
          green: '#30E02D',
          orange: '#CC8348',
          light: '#F0F0F0',
        },
      },
    },
  },
})
